@keyframes showTopText {
  0% { transform: translate3d(0, 100%, 0); }
  40%, 60% { transform: translate3d(0, 50%, 0); }
  100% { transform: translate3d(0, 0, 0); }
}

@keyframes showBottomText {
  0% { transform: translate3d(0, -100%, 0); }
  100% { transform: translate3d(0, 0, 0); }
}

.animated-title {
  color: rgb(45,46,63);
  font-family: sans-serif;
  height: 90vmin;
  right: 0%;
  position: absolute;
  top: 62%;
  transform: translateY(-50%);
  width: 100vmin;
  text-align: right;
}

.dark .animated-title {
  color: rgb(204, 243, 129);
}

.animated-title > div {
  height: 50%;
  overflow: hidden;
  position: absolute;
  width: 100%;
}

.animated-title > div div {
  font-size: 12vmin;
  padding: 2vmin 0;
  position: absolute;
  font-weight: 900;
}

.animated-title > div div span {
  display: block;
}

.animated-title > div.text-top {
  border-bottom: 1vmin solid rgb(45,46,63);
  top: 0;
  width: 72%;
}

.dark .animated-title > div.text-top {
  border-bottom: 1vmin solid rgb(204, 243, 129);
}

.animated-title > div.text-top div {
  animation: showTopText 0.75s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  bottom: 0;
  transform: translate(0, 100%);
}

.animated-title > div.text-top div span:first-child {
  color: rgb(45,46,63);
}

.dark .animated-title > div.text-top div span:first-child {
  color: rgb(204, 243, 129);
}

.animated-title > div.text-bottom {
  bottom: 0;
}

.animated-title > div.text-bottom div {
  animation: showBottomText 0.5s;
  animation-delay: 1.25s;
  animation-fill-mode: forwards;
  top: 0;
  right: 30%;
  transform: translate(0, -100%);
}


@media (max-width: 768px) {
  .animated-title {
    height: auto;
    width: 100%;
    top: 65%;
    text-align: center;
    transform: translateY(-50%);
  }

  .animated-title > div {
    height: auto;
    position: static;
  }

  .animated-title > div div {
    font-size: 9vmin;
    padding: 1vmin 0;
    position: static;
  }

  .animated-title > div.text-top {
    width: 100%;
    border-bottom: none;
  }

  .dark .animated-title > div.text-top {
  border-bottom: 1vmin solid rgb(0, 0, 0);
  }

  .animated-title > div.text-top div {
    bottom: auto;
    transform: none;
    animation: none;
  }

  .animated-title > div.text-bottom {
    bottom: auto;
  }

  .animated-title > div.text-bottom div {
    top: auto;
    right: auto;
    transform: none;
    animation: none;
  }
}




