.preloader {
    height: 100%;
    width: 100%;
    background-color: #000;
    color :#FFF;
    position  :fixed;
    bottom: 0;
    left: 0;
    right:0;
    z-index : 55;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow : hidden;
}

.preloader .texts-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    width: 300px;
    font-size: 20px;
    font-weight: 800;
    overflow: hidden;
    color : #FFF;
}